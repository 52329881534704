import {
  Box,
  Button,
  Checkbox,
  Chip,
  Link,
  Tooltip,
  Typography,
} from "@mui/joy";
import { alpha } from "@mui/material";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { useTour } from "@reactour/tour";
import Id from "atoms/Id/Id";
import { Assignment } from "entities/assignment";
import MercuryMercuryJob_Entity from "entities/mercuryJob/mercuryJob";
import { Mission, Mission_Entity } from "entities/mission";
import { Musician, Musician_Entity } from "entities/musician";
import { Stage } from "entities/stage";
import { ChairAssignMusician } from "features/chair/v2/chairAssignMusician";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import SeasonNotify from "features/seasons/SeasonNotify";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import GroupActions from "hooks/Layout/GroupActions/v2";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  selectedTagIDSelector,
  setBackToSeason,
  setFormOpen,
  setMercuryJobIDForHistory,
  setSelectedProjectID,
} from "reducers/rhapsody";
import {
  internalInitSelector,
  layoutDebugSelector,
  setAssignmentIDForLookup,
} from "reducers/v2/missionControl";
import {
  seasonUtilsSelector,
  selectMissions,
  selectedMissionsSelector,
  unselectMissions,
} from "reducers/v2/seasonControl";
import {
  useCreateMissionMutation,
  useMissionReplaceMusicianMutation,
  useUpdateMissionMutation,
} from "redux/mission/missionEndpoints";

/**
 *
 * @returns {ReactElement} SeasonMusicians page
 */
export function Missions() {
  const {
    missions,
    musicians,
    musiciansMap,
    stagesMap,
    missionsMap,
    seasonMercury,
    assignments,
    projects,
    activeMusicians,
    tag,
  } = useSelector(seasonUtilsSelector);
  const [updateMission] = useUpdateMissionMutation();
  const [createMission] = useCreateMissionMutation();
  const internalInit = useSelector(internalInitSelector);
  const [replace, setReplace] = useState({ from: undefined, to: undefined });
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  let interval;
  const datagridApi = useGridApiRef();

  useEffect(() => {
    if (musiciansMap[replace.to]) interval = setInterval(showMusician, 200);
  }, [musiciansMap]);

  const rows =
    activeMusicians?.reduce((a, v) => {
      const item = { ...v };
      projects.forEach((p) => {
        item[p.id] = true;
      });
      a.push(item);
      return a;
    }, []) ?? [];

  const showMusician = () => {
    datagridApi.current.scrollToIndexes({
      rowIndex: rows.findIndex((m) => m.id === replace.to),
      colIndex: 0,
    });
    const elemt = window.document.getElementsByClassName(
      `musician_${replace.to}`
    );

    if (elemt.length) {
      setSteps([
        {
          selector: `.musician_${replace.to}`,
          content: (
            <Box>
              <Typography level="h6" sx={{ color: "inherit" }}>
                Success
              </Typography>
              <Typography level="body2" sx={{ color: "inherit" }}>
                <b>
                  <MusicianAvatar
                    size={20}
                    musician={musiciansMap[replace.from]}
                  />{" "}
                  {musiciansMap[replace.from].fullName()}
                </b>{" "}
                has been replaced by{" "}
                <b>
                  <MusicianAvatar
                    size={20}
                    musician={musiciansMap[replace.to]}
                  />{" "}
                  {musiciansMap[replace.to].fullName()}
                </b>
              </Typography>
            </Box>
          ),
        },
      ]);
      setIsOpen(false);
      setCurrentStep(0);
      setIsOpen(true);
      setReplace({ from: undefined, to: undefined });
      clearInterval(interval);
    }
  };

  const onUpdate = async (_new: Musician & { memo: string }) => {
    return new Promise<Musician_Entity>((resolve) => {
      console.log(_new);
      const mission = missions.find((m) => m.musicianID === _new.id);
      const body = { ...missionsMap[mission.id], memo: _new.memo };
      updateMission({
        id: mission.id,
        body,
      });
      setTimeout(() => {
        resolve({ ..._new });
      }, 50);
    });
  };

  const createAllMissions = () => {
    musicians.forEach((musician) => {
      const mission = missions.find((m) => m.musicianID === musician.id);
      if (!mission) {
        createMission({
          tagID: tag.id,
          musicianID: musician.id,
        });
      }
    });
  };

  const columns: any = [
    {
      field: "musicianID",
      sortable: false,
      renderHeader: () => <MusicianHeader />,
      width: 200,
      headerName: "Musician",
      valueGetter: (p) => musiciansMap[p.row.id]?.lastName ?? "",
      renderCell: (p) => (
        <MusicianCell
          musician={musiciansMap[p.row.id]}
          mission={missions.find((m) => m.musicianID === p.row.id)}
        />
      ),
    },
    {
      field: "memo",
      sortable: false,
      headerName: "Mission Memo",
      editable: true,
      minWidth: 350,
      flex: 1,
      renderHeader: () => (
        <Box>
          <Typography level="body2">Mission Memo</Typography>
        </Box>
      ),
      renderCell: (p) => {
        const mission = missions.find((m) => m.musicianID === p.row.id);
        return (
          <Typography
            level="body4"
            sx={{
              opacity: mission?.memo ? 1 : 0.3,
              color: "black",
              fontWeight: mission?.memo ? 600 : undefined,
            }}
          >
            {mission?.memo ? mission.memo : `Any info to add for this mission?`}
          </Typography>
        );
      },
      type: "string",
    },
    {
      field: "id",
      sortable: false,
      width: 150,
      headerName: "Missions",
      headerAlign: "center",
      align: "right",
      renderCell: (p) => {
        const mission = missions.find((m) => m.musicianID === p.row.id);
        const job = seasonMercury.jobs.find(
          (j) => j.uuid === mission?.mercuryJobID
        );

        return (
          <SeasonMusicianMercury
            replaceCallback={(from, to) => setReplace({ from, to })}
            musicianID={p.row.id}
            job={job}
            stagesMap={stagesMap}
            mission={missions.find((m) => m.musicianID === p.row.id)}
          />
        );
      },
    },
  ];

  projects.map((project) => {
    columns.push({
      field: `${project.id}`,
      headerName: project.name,
      sortable: false,
      width: 150,
      align: "center",
      type: "boolean",
      renderCell: (p) => (
        <MusicianProject
          assignment={assignments.find(
            (a) => a.musicianID === p.row.id && `${a.projectID}` === p.field
          )}
          projectID={p.field}
          musicianID={p.row.id}
        />
      ),
      renderHeader: (p) => <ProjectHeader projectID={p.field} />,
    });
  });

  const hasUnmissionedMusicians = musicians.reduce((a, v) => {
    const mission = missions.find((m) => m.musicianID === v.id);
    if (!mission) a = true;
    return a;
  }, false);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        minHeight: "auto",
        height: 0,
        position: "relative",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {hasUnmissionedMusicians ? (
          <Button size="sm" onClick={createAllMissions}>
            Create All Missions
          </Button>
        ) : (
          []
        )}
      </Box>
      {musicians != undefined ? (
        <DataGridPremium
          apiRef={datagridApi}
          getRowClassName={(row) => {
            console.log(`musician_${row.id}`);
            return `musician_${row.id}`;
          }}
          sx={{ width: "calc(100vw - 40px)", maxWidth: "100%" }}
          disableColumnMenu
          disableColumnFilter
          processRowUpdate={onUpdate}
          columns={columns}
          initialState={{
            pinnedColumns: {
              right: ["id"],
              left: ["musicianID"],
            },
          }}
          rows={rows}
        />
      ) : (
        []
      )}
      {internalInit ? <GroupActions /> : []}
      <SeasonNotify />
    </Box>
  );
}

function MusicianProject({
  projectID,
  musicianID,
  assignment,
}: {
  projectID: number;
  musicianID: number;
  assignment?: Assignment;
}) {
  const dispatch = useDispatch();

  return (
    <Box
      onClick={
        assignment
          ? () => {
              dispatch(setSelectedProjectID(assignment.projectID));
              dispatch(setAssignmentIDForLookup(assignment.id));
              dispatch(
                setFormOpen({ isOpen: true, formID: "assignmentLookup" })
              );
            }
          : undefined
      }
      sx={{
        width: "100%",
        flex: 1,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {assignment ? <i className="fa-solid fa-check"></i> : []}
    </Box>
  );
}

function ProjectHeader({ projectID }) {
  const utils = useSelector(seasonUtilsSelector);
  const dispatch = useDispatch();
  const project = utils?.projectsMap[projectID];
  const workSessions = utils.workSessions.filter(
    (w) => w.projectID === project?.id
  );

  const firstWorkSession = workSessions?.length ? workSessions[0] : undefined;
  const lastWorkSession = workSessions?.length
    ? workSessions[workSessions.length - 1]
    : undefined;

  const firstWorkSessionDate = firstWorkSession
    ? moment(firstWorkSession.dateFromUTC).format("l")
    : "";
  const lastWorkSessionDate = lastWorkSession
    ? moment(lastWorkSession.dateFromUTC).format("l")
    : "";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", lineHeight: 1 }}>
      <Link
        component={RouterLink}
        onClick={() => {
          dispatch(setBackToSeason(`${utils.tag.id}/missions`));
        }}
        to={`${RouterConfig.projects}/${project.id}/mission-control`}
        level="body2"
      >
        {project?.name}
      </Link>
      <Typography level="body4">
        {workSessions?.length} Work Sessions
      </Typography>
      {firstWorkSession && lastWorkSession ? (
        <Typography level="body5">
          {firstWorkSessionDate === lastWorkSessionDate
            ? lastWorkSessionDate
            : `${firstWorkSessionDate} - ${lastWorkSessionDate}`}
        </Typography>
      ) : (
        []
      )}
    </Box>
  );
}

function MusicianCell({
  musician,
  mission,
}: {
  musician?: Musician;
  mission: Mission_Entity;
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {mission ? <MissionCheckbox missionIDs={[mission.id]} /> : []}

      <MusicianAvatar noBadge size={28} musician={musician} />
      <Box>
        <Typography
          level="body3"
          sx={{ color: "black", fontWeight: 600, fontSize: 12 }}
        >
          <b>{musician?.fullName()}</b>
        </Typography>
        {mission ? (
          <Box sx={{ display: "flex" }}>
            <Id
              tooltip="Assignment ID"
              startDecorator={<i className="fa-regular fa-box-open"></i>}
            >
              {mission?.id}
            </Id>
          </Box>
        ) : (
          []
        )}
      </Box>
    </Box>
  );
}

function SeasonMusicianMercury({
  musicianID,
  stagesMap,
  mission,
  job,
  replaceCallback,
}: {
  musicianID: number;
  stagesMap: {
    [id: number]: Stage;
  };
  mission: Mission;
  job?: MercuryMercuryJob_Entity;
  replaceCallback: (from: number, to: number) => void;
}) {
  const debug = useSelector(layoutDebugSelector);
  const tagID = useSelector(selectedTagIDSelector);
  const [createMission] = useCreateMissionMutation();
  const [replaceMusician] = useMissionReplaceMusicianMutation();

  const dispatch = useDispatch();

  const stage = stagesMap[job?.currentStageID];
  const color = stage?.color ?? "#bdbdbd";
  const background = alpha(color, 0.2);

  if (job)
    return (
      <Box
        sx={{ display: "flex", gap: 1 }}
        onClick={() => {
          dispatch(setMercuryJobIDForHistory(job.uuid));
          dispatch(setFormOpen({ isOpen: true, formID: "mercuryHistory" }));
        }}
      >
        <Chip
          size="sm"
          startDecorator={
            <img
              src={
                stage?.icon ??
                "https://storage.googleapis.com/rhapsody/logos/ready.png"
              }
              style={{ height: 12 }}
            />
          }
          sx={{ background, color }}
        >
          {stage?.wording() ?? "Ready"}
        </Chip>
        {debug ? (
          <Box>
            <Typography level="body5">mercuryJob: {job.uuid}</Typography>
            <Typography level="body5">musicianID: {musicianID}</Typography>
          </Box>
        ) : (
          []
        )}
        {stage?.terminus ? (
          <Tooltip
            variant="outlined"
            size="sm"
            arrow
            enterDelay={1000}
            enterNextDelay={1000}
            title="Replace Musician"
          >
            <Box
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <ChairAssignMusician
                musicianID={musicianID}
                onSelect={(e) => {
                  replaceCallback(musicianID, e);
                  replaceMusician({ id: mission.id, body: { musicianID: e } });
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    fontSize: 14,
                    p: 0.5,
                    color: "#6E6E6E",
                  }}
                >
                  <i className="fa-solid fa-rotate"></i>
                </Box>
              </ChairAssignMusician>
            </Box>
          </Tooltip>
        ) : (
          []
        )}
      </Box>
    );

  return (
    <Button
      size="sm"
      variant="soft"
      color={mission ? "success" : "neutral"}
      startDecorator={
        mission ? (
          <i className="fa-solid fa-check"></i>
        ) : (
          <i className="fa-solid fa-plus"></i>
        )
      }
      onClick={() => {
        createMission({
          tagID: tagID,
          musicianID: musicianID,
        });
      }}
    >
      {mission ? "Missioned" : "Add Mission"}
    </Button>
  );
}

export function MissionCheckbox({
  missionIDs,
  size,
}: {
  missionIDs: number[];
  size?: "sm" | "md" | "lg";
}) {
  const selectedMissionIDs = useSelector(selectedMissionsSelector);
  const dispatch = useDispatch();

  const { checked, intermediate } = missionIDs.reduce(
    (a, v) => {
      const index = selectedMissionIDs?.findIndex((p) => p === v);
      if (index === -1) a.checked = false;
      if (index >= 0) a.intermediate = true;
      return a;
    },
    {
      checked: missionIDs?.length === 0 ? false : true,
      intermediate: false,
    }
  );

  return (
    <Checkbox
      id="selecto-checkbox"
      onChange={(e) => {
        if (!e.target.checked) {
          return dispatch(unselectMissions(missionIDs));
        }
        dispatch(selectMissions(missionIDs));
      }}
      checkedIcon={
        !checked && intermediate ? (
          <i className="fa-solid fa-minus"></i>
        ) : undefined
      }
      variant={intermediate && !checked ? "soft" : undefined}
      size={size}
      checked={checked || intermediate}
    />
  );
}

function MusicianHeader() {
  const seasonUtils = useSelector(seasonUtilsSelector);

  const allMissionIDs: number[] = seasonUtils.missions.reduce((a, v) => {
    a.push(v.id);

    return a;
  }, []);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <MissionCheckbox missionIDs={allMissionIDs} />
      <Typography level="body2">Musicians</Typography>
    </Box>
  );
}
