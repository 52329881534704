import Group_Entity from "./group";
export default class Group extends Group_Entity {
  static fromList(groupsJSON: unknown): Array<Group> {
    const groups: Group[] = [];
    if (groupsJSON)
      Array.isArray(groupsJSON) &&
        groupsJSON.forEach((groupJSON) => {
          groups.push(new Group(groupJSON));
        });
    return groups;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
