import { Inspector } from "../inspector";
import { Interactor } from "../interactor";
import { Channel } from "../channel";
import { Job } from "../job";
import { MercuryJob_Entity } from "entities/mercuryJob";

/**
 * Stage_Entity type guard.
 *
 * @param {any} stageJson Stage object from API
 * @returns {boolean} Return true if type is Stage_Entity
 */

/**
 * @param stageJson
 */

export default class Stage_Entity {
  constructor(stageJson: Stage_Entity) {
    try {
      this.id = stageJson.id;
      this.pipelineID = stageJson.pipelineID;
      this.name = stageJson.name;
      this.terminus = stageJson.terminus;
      this.icon = stageJson.icon;
      this.color = stageJson.color;
      this.primary = stageJson.primary;
      this.manual = stageJson.manual;
      this.nudgeable = stageJson.nudgeable;
      this.silent = stageJson.silent;
      this.available = stageJson.available;
      this.excluded = stageJson.excluded;
      this.position = stageJson.position;
      this.interactors = stageJson.interactors;
      this.inspectors = stageJson.inspectors;
      this.channels = stageJson.channels;
      this.jobs = stageJson.jobs;
      this.jobCount = stageJson.jobCount;
      this.nudgeCount = stageJson.nudgeCount;
      this.createdAt = stageJson.createdAt;
      this.createdBy = stageJson.createdBy;
      this.relationID = stageJson.relationID;
      this.data = stageJson.data;
      this.jobIDs = stageJson.jobIDs;
      this.acknowledged = stageJson.acknowledged;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Stage_Entity.name }: ${e}"
      );
    }
  }

  id?: number;
  pipelineID?: number;
  terminus?: boolean;
  name?: string;
  icon?: string;
  color?: string;
  primary?: boolean;
  manual?: boolean;
  nudgeable?: boolean;
  silent?: boolean;
  available?: string;
  excluded?: string;
  position?: string;
  interactors?: Array<Interactor>;
  inspectors?: Array<Inspector>;
  channels?: Array<Channel>;
  jobs?: Array<MercuryJob_Entity>;
  jobCount?: number;
  nudgeCount?: number;
  createdAt?: string;
  createdBy?: string;
  relationID?: number;
  jobIDs?: number[];
  acknowledged?: boolean;
  data?: string;
}
