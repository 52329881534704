import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Switch,
  Tab,
  TabList,
  Tabs,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import { DialogContent, DialogTitle } from "@mui/material";
import ColorPicker from "atoms/ColorPicker";
import DialogClose from "atoms/DialogClose/DialogClose";
import { AppConfig } from "environement";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { SoloButton } from "features/projects/ProjectMissionControl/utils";
import { copyTextToClipboard } from "helpers";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  selectedProjectRosterIDSelector,
  setFormOpen,
  setSelectedProjectRosterID,
} from "reducers/rhapsody";
import {
  layoutUtilsSelector,
  selectionSelector,
} from "reducers/v2/missionControl";
import { useUpdateCurrentCompanyMutation } from "redux/company/companyEndpoints";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";
import { useProject } from "redux/project/projectHooks";
import {
  useCreateProjectRosterMutation,
  useDeleteProjectRosterMutation,
  useUpdateProjectRosterMutation,
} from "redux/projectRoster/projectRosterEndpoints";
import { useProjectRoster } from "redux/projectRoster/projectRosterHooks";

const fonts = ["Helvetica", "Courier", "Times-Roman"];
let lastPDFRefresh: string;

export type RosterOptions = {
  active?: boolean;
  venue?: boolean;
  date?: boolean;
  chairNumber?: boolean;
  emptyChair?: boolean;
  emptyChairColor?: string;
  role?: boolean;
  roleColor?: string;
  doublingInstruments?: boolean;
  doublingInstrumentsColor?: string;
  title?: string;
  subtitle?: string;
  tbd?: boolean;
  tbdColor?: string;
  unconfirmedColor?: string;
  tbdLabel?: string;
  stageColor?: boolean;
  repertoire?: boolean;
  splitFamily?: boolean;
  columns?: number;
  font: "Helvetica" | "Courier" | "Times-Roman";
  fontBold: "Helvetica-Bold" | "Courier-Bold" | "Times-Bold";
  fontItalic: "Helvetica-Oblique" | "Courier-Oblique" | "Times-Italic";
  fontSizeSm: number;
  fontSizeMd: number;
  fontSizeLg: number;
  pageNumber?: boolean;
  assignmentMemo?: boolean;
  memoColor?: string;
  talkback?: boolean;
  talkbackColor?: string;
  workSessionIDs?: number[];
  projectPieceIDs?: number[];
  inactiveMessage?: string;
};
/**
 *
 * @returns {ReactElement} projectPieceShorthand page
 */
export function ProjectRoster() {
  const open = useSelector(formOpenSelector("roster"));
  const projectID = useSelector(selectedProjectIDSelector);
  const [projectPieceIDs, setProjectPieceIDs] = useState([]);
  const [workSessionIDs, setWorkSessionIDs] = useState([]);
  const { workSessions, projectPieces, piecesMap } =
    useSelector(layoutUtilsSelector);
  const { selectedProjectPieceIDs, selectedWorkSessionIDs } =
    useSelector(selectionSelector);
  const { project } = useProject(projectID);
  const dispatch = useDispatch();
  const [pdf, setPDF] = useState("");
  const settings = useCompanySettings();
  const { company } = useCurrentCompany();
  const [updateCompany] = useUpdateCurrentCompanyMutation();
  const [deleteRoster] = useDeleteProjectRosterMutation();
  const [updateRoster] = useUpdateProjectRosterMutation();
  const [createProjectRoster] = useCreateProjectRosterMutation();
  const selectedProjectRosterID = useSelector(selectedProjectRosterIDSelector);
  const { projectRoster, isLoading } = useProjectRoster(
    selectedProjectRosterID
  );
  const nameRef = useRef();
  const titleRef = useRef();
  const subtitleRef = useRef();
  const tbdLabelRef = useRef();
  const inactiveMessageRef = useRef();
  const askQuestion = useAskQuestion();
  const [options, setOptions] = useState<Partial<RosterOptions>>({
    active: true,
    chairNumber: true,
    venue: true,
    date: true,
    emptyChair: true,
    emptyChairColor: "#9e9e9e",
    role: true,
    roleColor: "grey",
    doublingInstruments: true,
    doublingInstrumentsColor: "#9c27b0",
    title: "",
    subtitle: "",
    tbd: false,
    tbdColor: "red",
    tbdLabel: "TBA",
    unconfirmedColor: "#9e9e9e",
    stageColor: false,
    repertoire: false,
    splitFamily: false,
    columns: 4,
    font: "Helvetica",
    fontBold: "Helvetica-Bold",
    fontItalic: "Helvetica-Oblique",
    fontSizeSm: 12,
    fontSizeMd: 14,
    fontSizeLg: 24,
    pageNumber: true,
    assignmentMemo: true,
    talkback: false,
    talkbackColor: "#2196f3",
    memoColor: "#ff9800",
    inactiveMessage: "Rhapsody Live Roster is not active",
  });

  useEffect(() => {
    if (settings.rosterOptions) {
      setOptions({ ...settings.rosterOptions, title: "", subtitle: "" });
    }
  }, [settings]);

  useEffect(() => {
    if (projectRoster?.id) {
      let o: RosterOptions;
      try {
        o = JSON.parse(projectRoster.options);
      } catch (error) {}
      setOptions((e) => ({ ...e, ...o }));
    } else {
      setOptions((e) => ({ ...e, active: true }));
    }
  }, [projectRoster]);

  useEffect(() => {
    if (!projectRoster && !isLoading)
      setOptions((o) => ({
        ...o,
        workSessionIDs: workSessionIDs,
        projectPieceIDs: projectPieceIDs,
      }));
  }, [workSessionIDs, projectPieceIDs, isLoading, projectRoster]);

  useEffect(() => {
    if (!isLoading) refreshPDF();
  }, [options, isLoading]);

  useEffect(() => {
    setProjectPieceIDs(
      Object.keys(selectedProjectPieceIDs)
        .map((e) => (selectedProjectPieceIDs[e] ? parseInt(e) : undefined))
        .filter((e) => e)
    );
  }, [selectedProjectPieceIDs]);

  useEffect(() => {
    setWorkSessionIDs(
      Object.keys(selectedWorkSessionIDs)
        .map((e) => (selectedWorkSessionIDs[e] ? parseInt(e) : undefined))
        .filter((e) => e)
    );
  }, [selectedWorkSessionIDs]);

  const selectAllProjectPieces = () => {
    setProjectPieceIDs(
      projectPieces.reduce((a, v) => {
        a.push(v.id);
        return a;
      }, [])
    );
  };

  const hasChanged = () => {
    let changed = false;
    let _o: RosterOptions;
    try {
      _o = JSON.parse(projectRoster.options);
    } catch (error) {}
    for (const key in options) {
      if (Object.prototype.hasOwnProperty.call(options, key)) {
        if (`${options[key]}` !== `${_o[key]}`) {
          console.log(key, `${options[key]}`, `${_o[key]}`);
          changed = true;
        }
      }
    }

    return changed;
  };

  const selectAllWorkSessions = () => {
    setWorkSessionIDs(
      workSessions.reduce((a, v) => {
        a.push(v.id);
        return a;
      }, [])
    );
  };

  const refreshPDF = async () => {
    if (!options.workSessionIDs) return;
    if (lastPDFRefresh && moment().diff(moment(lastPDFRefresh), "second") < 1) {
      console.log("Skipping");
      return;
    }
    const apiEndpoint = AppConfig.api.endpoint;
    let endpoint = "live";
    if (apiEndpoint.includes("alpha")) endpoint = "alpha";
    if (apiEndpoint.includes("demo")) endpoint = "demo";
    lastPDFRefresh = moment().utc().format();
    await fetch(
      // `http://localhost:3030?projectID=${projectID}`,
      `https://roster.${endpoint}.rhapsody.la?projectID=${projectID}`,
      {
        body: JSON.stringify(options),
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
      }
    )
      .then((response) => response.blob())
      .then((d) => {
        setPDF(window.URL.createObjectURL(d));
      });
  };

  const onClose = () => {
    const _companyOptions = { ...options };
    delete _companyOptions.active;
    delete _companyOptions.workSessionIDs;
    delete _companyOptions.projectPieceIDs;

    updateCompany({
      ...company,
      settings: JSON.stringify({
        ...settings,
        rosterOptions: _companyOptions,
      }),
    });
    dispatch(setFormOpen({ isOpen: false, formID: "roster" }));
    dispatch(setSelectedProjectRosterID(null));
  };

  const askDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>
          You are about to delete a Live Roster, please confirm
        </Typography>
      ),
    }).then((i) => {
      if (i == 1) confirmDelete();
    });
  };

  const confirmClose = () => {
    askQuestion("Are you sure you want to close?", ["Go back", "Close"], {
      subtitle: (
        <Typography>
          Your roster configuration will be lost, changes will be discarded.
        </Typography>
      ),
    }).then((i) => {
      if (i == 1) onClose();
    });
  };

  const confirmDelete = () => {
    deleteRoster(projectRoster.id);
    onClose();
  };

  const downloadPDF = async () => {
    const apiEndpoint = AppConfig.api.endpoint;
    let endpoint = "live";
    if (apiEndpoint.includes("alpha")) endpoint = "alpha";
    if (apiEndpoint.includes("demo")) endpoint = "demo";
    await fetch(
      `https://roster.${endpoint}.rhapsody.la?projectID=${projectID}`,
      {
        body: JSON.stringify(options),
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
      }
    )
      .then((response) => response.blob())
      .then((d) => {
        downloadBlob(
          d,
          (nameRef as any).current.value
            ? `${(nameRef as any).current.value}.pdf`
            : `${project.name}_${moment().format("MM/DD/YYYY")}.pdf`
        );
      });
  };

  return (
    <DialogClose
      open={open}
      onClose={
        projectRoster
          ? () => {
              if (hasChanged()) {
                confirmClose();
              } else {
                onClose();
              }
            }
          : confirmClose
      }
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle
        sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}
      >
        <Input
          slotProps={{ input: { ref: nameRef } }}
          startDecorator={<i className="fa-solid fa-file-pdf"></i>}
          placeholder="Roster Document Name"
          fullWidth
          key={projectRoster?.name}
          defaultValue={projectRoster?.name}
        />
        <Tooltip title="Download in PDF" arrow variant="outlined">
          <IconButton onClick={downloadPDF} color="neutral" variant="solid">
            <i className="fa-sharp fa-solid fa-arrow-down"></i>
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          display: "flex",
          p: 0,
          height: "calc(100vh - 160px)",
          position: "relative",
        }}
      >
        {pdf ? (
          <iframe title="preview" src={pdf} style={{ flexGrow: 1 }} />
        ) : (
          <Box style={{ flexGrow: 1, background: "#525659" }} />
        )}
        <Divider />
        <Box
          sx={{
            background: "#fafafa",
            width: 320,
            display: "flex",
            gap: 1,
            flexDirection: "column",
            // maxHeight: "calc(100vh - 160px)",
            overflowY: "auto",
            minHeight: "auto",
            height: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                level="h6"
                startDecorator={<i className="fa-solid fa-gear"></i>}
              >
                Configuration
              </Typography>
              <Chip
                color="info"
                onClick={() =>
                  window.open(
                    "https://helpdesk.rhapsody.la/rhapsody-helpdesk/rhapsody-features/updates/live-rosters",
                    "_blank"
                  )
                }
                startDecorator={<i className="fa-solid fa-graduation-cap"></i>}
                size="sm"
                variant="soft"
              >
                Learn More
              </Chip>
            </Box>
            {projectRoster ? (
              <Alert color={options.active ? "success" : "danger"}>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ display: "flex", justifyContent: "spaceBetween" }}>
                    <Typography sx={{ color: "inherit", width: "100%" }}>
                      <b>Active</b>
                    </Typography>
                    <Switch
                      size="sm"
                      onChange={() =>
                        setOptions((e) => ({
                          ...e,
                          active: !(e.active ?? true),
                        }))
                      }
                      color={options.active ? "success" : "danger"}
                      checked={options.active}
                    />
                  </Box>

                  <Typography level="body2" sx={{ color: "inherit" }}>
                    {options.active
                      ? "Anyone with the link can see the Roster."
                      : "Nobody can see the Roster."}
                  </Typography>
                  <Input
                    readOnly
                    endDecorator={
                      <Tooltip
                        title="Copy to clipboard"
                        arrow
                        size="sm"
                        variant="outlined"
                      >
                        <IconButton
                          color="neutral"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            copyTextToClipboard(projectRoster.publicUrl());
                          }}
                        >
                          <i className="fa-solid fa-copy"></i>
                        </IconButton>
                      </Tooltip>
                    }
                    size="sm"
                    value={projectRoster.publicUrl()}
                  />
                </Box>
              </Alert>
            ) : (
              []
            )}
            <Box>
              <Typography level="body2">Title</Typography>
              <Input
                onBlur={() => {
                  setOptions((o) => ({
                    ...o,
                    title: (titleRef as any).current.value,
                  }));
                }}
                slotProps={{ input: { ref: titleRef } }}
                defaultValue={options.title ?? ""}
                placeholder={project?.name}
                variant="outlined"
              />
            </Box>
            <Box>
              <Typography level="body2">Subtitle</Typography>
              <Textarea
                onBlur={() => {
                  setOptions((o) => ({
                    ...o,
                    subtitle: (subtitleRef as any).current.value,
                  }));
                }}
                slotProps={{ textarea: { ref: subtitleRef } }}
                defaultValue={options.subtitle ?? ""}
                placeholder="Ex: Conducted by David Afkham, Ray Chen Violin Soloist"
                maxRows={5}
                minRows={2}
                variant="outlined"
              />
            </Box>
            <Box>
              <Typography level="body2">Font</Typography>
              <Tabs
                aria-label="Plain tabs"
                value={options.font ?? "Helvetica"}
                size="sm"
                onChange={(event, value) =>
                  setOptions((o) => ({
                    ...o,
                    font: value as "Helvetica" | "Courier" | "Times-Roman",
                  }))
                }
                sx={{ borderRadius: "lg" }}
              >
                <TabList variant="outlined">
                  {fonts.map((f) => (
                    <Tab
                      variant={options.font === f ? "soft" : "plain"}
                      color={options.font === f ? "primary" : "neutral"}
                      key={f}
                      sx={{ fontFamily: f }}
                      value={f}
                    >
                      {f}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            </Box>
            <Box>
              <Typography level="body2">Font Size</Typography>
              <Tabs
                aria-label="Plain tabs"
                value={options.fontSizeMd}
                size="sm"
                onChange={(event, value) =>
                  setOptions((o) => ({
                    ...o,
                    fontSizeMd: value as number,
                    fontSizeSm: (value as number) - 3,
                  }))
                }
                sx={{ borderRadius: "lg" }}
              >
                <TabList variant="outlined">
                  <Tab
                    value={10}
                    variant={options.fontSizeMd === 10 ? "soft" : "plain"}
                    color={options.fontSizeMd === 10 ? "primary" : "neutral"}
                    sx={{ fontSize: 10 }}
                  >
                    Small
                  </Tab>
                  <Tab
                    value={12}
                    variant={options.fontSizeMd === 12 ? "soft" : "plain"}
                    color={options.fontSizeMd === 12 ? "primary" : "neutral"}
                    sx={{ fontSize: 12 }}
                  >
                    Medium
                  </Tab>
                  <Tab
                    value={14}
                    variant={options.fontSizeMd === 14 ? "soft" : "plain"}
                    color={options.fontSizeMd === 14 ? "primary" : "neutral"}
                    sx={{ fontSize: 14 }}
                  >
                    Large
                  </Tab>
                </TabList>
              </Tabs>
            </Box>
            <Box>
              <Typography level="body2">Columns</Typography>
              <Tabs
                aria-label="Plain tabs"
                value={options.columns ?? 4}
                size="sm"
                onChange={(event, value) =>
                  setOptions((o) => ({ ...o, columns: value as number }))
                }
                sx={{ borderRadius: "lg" }}
              >
                <TabList variant="outlined">
                  {[2, 3, 4, 5].map((i) => (
                    <Tab
                      variant={(options?.columns ?? 4) === i ? "soft" : "plain"}
                      color={
                        (options?.columns ?? 4) === i ? "primary" : "neutral"
                      }
                      value={i}
                      key={i}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 0.5,
                          width: "100%",
                          height: 20,
                          alignItems: "stretch",
                        }}
                      >
                        {Array.from(Array(i)).map((e) => (
                          <Sheet
                            sx={{ flex: 1, height: 20, borderRadius: 2 }}
                            variant="solid"
                            key={e}
                          ></Sheet>
                        ))}
                      </Box>
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            </Box>
            <Box>
              <Typography level="body2">Content</Typography>
              <Sheet
                variant="outlined"
                sx={{ background: "white", borderRadius: "16px", p: 1 }}
              >
                <List sx={{ gap: 1 }}>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          date: !(e.date ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox size="sm" checked={options.date ?? true} />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-solid fa-calendar"></i>
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Date(s)
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          venue: !(e.venue ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox size="sm" checked={options.venue ?? true} />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-solid fa-location-dot"></i>
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Venue
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          repertoire: !(e.repertoire ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox
                          size="sm"
                          checked={options.repertoire ?? true}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={<i className="fa-solid fa-music"></i>}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Repertoire
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          tbd: !(e.tbd ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox size="sm" checked={!(options.tbd ?? true)} />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-solid fa-block-question"></i>
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Unconfirmed Musicians
                            <Typography level="body4">
                              Shows "
                              {options.tbdLabel ? options.tbdLabel : "TBD"}"
                              instead when turned off
                            </Typography>
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          stageColor: !(e.stageColor ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox
                          size="sm"
                          checked={options.stageColor ?? true}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-solid fa-palette"></i>
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Stage Color
                            <Typography level="body4">
                              Musician name will be in the status' color
                            </Typography>
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          emptyChair: !(e.emptyChair ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox
                          size="sm"
                          checked={options.emptyChair ?? true}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={<i className="fa-solid fa-chair" />}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Empty Chairs
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          chairNumber: !(e.chairNumber ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox
                          size="sm"
                          checked={options.chairNumber ?? true}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-solid fa-hashtag"></i>
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Chair Number
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({ ...e, role: !(e.role ?? true) }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox size="sm" checked={options.role ?? true} />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-solid fa-hat-cowboy" />
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Role
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          doublingInstruments: !(e.doublingInstruments ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox
                          size="sm"
                          checked={options.doublingInstruments ?? true}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-solid fa-clarinet" />
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Doubling Instruments
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          splitFamily: !(e.splitFamily ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox
                          size="sm"
                          checked={options.splitFamily ?? true}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-solid fa-arrow-turn-down-left"></i>
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Split Family
                            <Typography level="body4">
                              Returns to a new line on new family
                            </Typography>
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          assignmentMemo: !(e.assignmentMemo ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox
                          size="sm"
                          checked={options.assignmentMemo ?? true}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={<i className="fa-solid fa-memo"></i>}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Assignment Memo
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          talkback: !(e.talkback ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox
                          size="sm"
                          checked={options.talkback ?? true}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-solid fa-comment"></i>
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Musician's Message
                            <Typography level="body4">
                              Shows the latest message
                            </Typography>
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        setOptions((e) => ({
                          ...e,
                          pageNumber: !(e.pageNumber ?? true),
                        }))
                      }
                    >
                      <ListItemDecorator>
                        <Checkbox
                          size="sm"
                          checked={options.pageNumber ?? true}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography
                          level="body2"
                          startDecorator={
                            <i className="fa-regular fa-hashtag"></i>
                          }
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            Show Page Number
                          </Box>
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Sheet>
            </Box>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography level="body2">Work Sessions:</Typography>
                {workSessions.length > 1 ? (
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={selectAllWorkSessions}
                    level="body3"
                  >
                    Select All
                  </Typography>
                ) : (
                  []
                )}
              </Box>
              <Sheet
                variant="outlined"
                sx={{ background: "white", borderRadius: "16px", p: 1 }}
              >
                <List size="sm">
                  {workSessions.map((w) => {
                    const selected = workSessionIDs.includes(w.id);
                    return (
                      <ListItemButton
                        selected={selected}
                        onClick={() => {
                          if (selected) {
                            setWorkSessionIDs((e) =>
                              e.filter((i) => i !== w.id)
                            );
                          } else {
                            setWorkSessionIDs((e) => [...e, w.id]);
                          }
                        }}
                        key={w.id}
                        sx={{ gap: 1 }}
                      >
                        <Checkbox size="sm" checked={selected ?? false} />
                        <Box>
                          <Typography level="body2">{w?.title}</Typography>
                          <Typography level="body4">
                            {w?.formatDateRange()}
                          </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <SoloButton
                          onClick={() => {
                            selectAllProjectPieces();
                            setWorkSessionIDs([w.id]);
                          }}
                        />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Sheet>
            </Box>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography level="body2">Project Pieces:</Typography>
                {projectPieces.length > 1 ? (
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={selectAllProjectPieces}
                    level="body3"
                  >
                    Select All
                  </Typography>
                ) : (
                  []
                )}
              </Box>
              <Sheet
                variant="outlined"
                sx={{ background: "white", borderRadius: "16px", p: 1 }}
              >
                <List size="sm">
                  {projectPieces.map((p) => {
                    const piece = piecesMap[p.pieceID];
                    const selected = projectPieceIDs.includes(p.id);
                    return (
                      <ListItemButton
                        selected={selected}
                        onClick={() => {
                          if (selected) {
                            setProjectPieceIDs((e) =>
                              e.filter((i) => i !== p.id)
                            );
                          } else {
                            setProjectPieceIDs((e) => [...e, p.id]);
                          }
                        }}
                        key={p.id}
                        sx={{ gap: 1 }}
                      >
                        <Checkbox size="sm" checked={selected ?? false} />
                        <Box>
                          <Typography level="body2">
                            {piece?.name ?? "Seating assignment"}
                          </Typography>
                          <Typography level="body4">
                            {piece?.composer ?? ""}
                          </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <SoloButton
                          onClick={() => {
                            selectAllWorkSessions();
                            setProjectPieceIDs([p.id]);
                          }}
                        />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Sheet>
            </Box>
            <Box>
              <Typography level="body2">Text Colors</Typography>
              <Sheet
                variant="outlined"
                sx={{ background: "white", borderRadius: "16px", p: 1 }}
              >
                <ListItem>
                  <ListItemContent>
                    <Typography level="body2">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        Unconfirmed Musicians
                      </Box>
                    </Typography>
                  </ListItemContent>
                  <ListItemDecorator sx={{ mr: 1 }}>
                    <ColorPicker
                      label=""
                      color={options.unconfirmedColor}
                      onChange={(e) =>
                        setOptions((o) => ({
                          ...o,
                          unconfirmedColor: e,
                        }))
                      }
                    />
                  </ListItemDecorator>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <Typography level="body2">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        Doubling Instruments
                      </Box>
                    </Typography>
                  </ListItemContent>
                  <ListItemDecorator sx={{ mr: 1 }}>
                    <ColorPicker
                      label=""
                      color={options.doublingInstrumentsColor}
                      onChange={(e) =>
                        setOptions((o) => ({
                          ...o,
                          doublingInstrumentsColor: e,
                        }))
                      }
                    />
                  </ListItemDecorator>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <Typography level="body2">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        Empty Chairs
                      </Box>
                    </Typography>
                  </ListItemContent>
                  <ListItemDecorator sx={{ mr: 1 }}>
                    <ColorPicker
                      label=""
                      color={options.emptyChairColor}
                      onChange={(e) =>
                        setOptions((o) => ({
                          ...o,
                          emptyChairColor: e,
                        }))
                      }
                    />
                  </ListItemDecorator>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <Typography level="body2">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        Role
                      </Box>
                    </Typography>
                  </ListItemContent>
                  <ListItemDecorator sx={{ mr: 1 }}>
                    <ColorPicker
                      label=""
                      color={options.roleColor}
                      onChange={(e) =>
                        setOptions((o) => ({
                          ...o,
                          roleColor: e,
                        }))
                      }
                    />
                  </ListItemDecorator>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <Typography level="body2">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        Musician Message
                      </Box>
                    </Typography>
                  </ListItemContent>
                  <ListItemDecorator sx={{ mr: 1 }}>
                    <ColorPicker
                      label=""
                      color={options.talkbackColor}
                      onChange={(e) =>
                        setOptions((o) => ({
                          ...o,
                          talkbackColor: e,
                        }))
                      }
                    />
                  </ListItemDecorator>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <Typography level="body2">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        Assignment Memo
                      </Box>
                    </Typography>
                  </ListItemContent>
                  <ListItemDecorator sx={{ mr: 1 }}>
                    <ColorPicker
                      label=""
                      color={options.memoColor}
                      onChange={(e) =>
                        setOptions((o) => ({
                          ...o,
                          memoColor: e,
                        }))
                      }
                    />
                  </ListItemDecorator>
                </ListItem>
              </Sheet>
            </Box>
            <Box>
              <Typography level="body2">Other</Typography>
              <Sheet
                variant="outlined"
                sx={{
                  background: "white",
                  borderRadius: "16px",
                  p: 1,
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Input
                    defaultValue={options.tbdLabel}
                    onBlur={() => {
                      setOptions((o) => ({
                        ...o,
                        tbdLabel: (tbdLabelRef as any).current.value,
                      }));
                    }}
                    slotProps={{ input: { ref: tbdLabelRef } }}
                    placeholder="TBD"
                  />
                  <Typography level="body4">
                    Change "TBD" with a custom label
                  </Typography>
                </Box>
                <Box>
                  <Textarea
                    defaultValue={options.inactiveMessage}
                    onBlur={() => {
                      setOptions((o) => ({
                        ...o,
                        inactiveMessage: (inactiveMessageRef as any).current
                          .value,
                      }));
                    }}
                    slotProps={{ textarea: { ref: inactiveMessageRef } }}
                    placeholder="Rhapsody Live Roster is not active"
                  />
                  <Typography level="body4">
                    Custom message when the Roster is inactive.
                  </Typography>
                </Box>
              </Sheet>
            </Box>
          </Box>
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              p: 1,
              background: "white",
              borderTop: "solid 1px #bdbdbd",
              display: "flex",
              gap: 1,
              flexDirection: "column",
              zIndex: 9999,
              height: 100,
            }}
          >
            {!projectRoster ? (
              <Button
                fullWidth
                variant="solid"
                color="primary"
                onClick={() => {
                  createProjectRoster({
                    projectID: project.id,
                    options: JSON.stringify(options),
                    name:
                      (nameRef as any).current.value.length > 0
                        ? (nameRef as any).current.value
                        : project?.name,
                  });
                  onClose();
                }}
                startDecorator={<i className="fa-regular fa-users"></i>}
              >
                Save as a Roster Live Link
              </Button>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => {
                    askDelete();
                  }}
                  variant="soft"
                  color="danger"
                >
                  Delete
                </Button>
                <Button
                  onClick={() => {
                    updateRoster({
                      id: projectRoster.id,
                      body: {
                        ...projectRoster,
                        options: JSON.stringify(options),
                        name: (nameRef as any).current.value,
                      },
                    });
                    onClose();
                  }}
                >
                  Save
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </DialogClose>
  );
}

export function downloadBlob(blob, fileName) {
  const win: any = window;
  if (win.navigator && win.navigator.msSaveOrOpenBlob)
    return win.navigator.msSaveOrOpenBlob(blob);

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = win.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = data;
  link.download = fileName;

  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: win,
    })
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    win.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
}
